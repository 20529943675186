export const signUpEmail = async (email, recaptchaResponse) => {
  const ret = await fetch('https://staging-softwarelogos.herokuapp.com/email_list_add', {
    method: 'POST',
    body: JSON.stringify({email: email, recaptcha: recaptchaResponse}),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      response.json();
    }).then(data => {
      return data;
    }).catch(error => {
      console.log(error)
      return {error: error};
    });
  return ret;
};
