import { useEffect, useReducer } from 'react';
import AOS from 'aos';
import './App.css';
import { HIDE, initialState, reducer } from './state/signUpReducer.js';
import AboveTheFold from './components/AboveTheFold.js';
import Footer from './components/Footer.js';
import Benefits from './components/Benefits.js';
import SocialProof from './components/SocialProof.js';
import Navigation from './components/Navigation.js';
import SummaryBlurb from './components/SummaryBlurb.js';
import Pricing from './components/Pricing.js';
import SignUpForm from './components/SignUpForm.js';
import {
  data,
} from './data/PageData.js';
import {
  Modal,
} from 'react-bootstrap';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    AOS.init()
  }, []);

  const handleClose = () => {
    dispatch({type: HIDE});
  }

  return (
    <div className="App">
      { data.summary_blurb &&
        (<Navigation data={data.navigation} hasPricing={data.pricing}/>)
      }
      <AboveTheFold data={data.above_the_fold} dispatch={dispatch}/>
      { data.summary_blurb &&
        (
            <SummaryBlurb text={data.summary_blurb}/>
        )
      }
      { data.social_proof &&
        (<SocialProof data={data.social_proof}/>)
      }
      { data.benefits &&
        (<Benefits data={data.benefits}/>)
      }
      { data.pricing &&
        (<Pricing data={data.pricing} dispatch={dispatch}/>)
      }
      { data.footer &&
        (<Footer data={data.footer}/>)
      }

      <Modal centered show={state.showModal} onHide={handleClose}>
        <Modal.Body>
          <SignUpForm dispatch={dispatch} data={data.sign_up}/>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default App;
