import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import './SummaryBlurb.css';

const SummaryBlurb = ({text}) => {
  return (
    <Container className="SummaryBlurb-container pt-4 pb-4">
      <Row>
        <Col>
          {text}
        </Col>
      </Row>
    </Container>
  );
}

export default SummaryBlurb;
