import {
  Navbar,
  Nav,
  Container,
} from 'react-bootstrap';
import './Navigation.css';

const Navigation = ({data, hasPricing}) => {
  return (
    <Navbar>
      <Container fluid>
        <Navbar.Brand href="#home">
          {data.logo_image &&
            (<img src={data.logo_image} alt="logo" className="Navigation-logo"/>)
          }
          {data.logo_text && (
            <span className="Navigation-brand-name">{data.logo_text}</span>
          )}
        </Navbar.Brand>
        {hasPricing && false &&
          <Nav>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <Nav.Link>Have an account? Log in</Nav.Link>
          </Nav>
        }
      </Container>
    </Navbar>
  );
};

export default Navigation;

