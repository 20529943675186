import './Footer.css';

const Footer = ({data}) => {
  return (
    <div>
      { data.incorporated && (
        <div className="mb-3 Footer-incorporated">
          <small>{data.incorporated}</small>
        </div>
      )}
      { data.text && (
        <div>
          <small>{data.text}</small>
        </div>
      )}
      { data.disclaimer && (
        <div className="Footer-disclaimer">
          <small>{data.disclaimer}</small>
        </div>
      )}
    </div>
  );
};

export default Footer;
