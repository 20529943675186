import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import './SocialProof.css';

const SocialProof = ({data}) => {
  const getRows = () => {
    const els = data.proofLogos.map((logo, index) => {
      // only show 12 logos on small screen sizes
      const dVal = (index > 11) ? "d-none d-md-inline" : "";
      const interval = Math.floor(Math.random() * 700);
      return (
        <Col data-aos="fade-up" data-aos-duration={interval} key={index} className={`mt-5 ${dVal}`} xs={4} sm={2}> 
          <img src={logo} alt="test" className="SocialProof-image-square"/>
        </Col>
      )
    });
    return (
      <Row>
        {els}
      </Row>
    )
  }
  return (
    <Container className="SocialProof-container">
      {data.startText &&
        (
          <Row data-aos="fade-up" className="mt-2">
            <Col>
              {data.startText}
            </Col>
          </Row>
        )
      }
      {getRows()}
      {data.endText &&
        (
          <Row data-aos="fade-up" className="mt-5">
            <Col>
              {data.endText}
            </Col>
          </Row>
        )
      }
    </Container>
  );
};

export default SocialProof;
