import './AboveTheFold.css';
import {
  Container,
  Row,
  Col,
  Stack,
  Button,
} from 'react-bootstrap';
import { SHOW } from '../state/signUpReducer.js';

const AboveTheFold = ({data, dispatch}) => {
  return (
    <Container fluid className="AboveTheFold-container">
      <Row className="h-100 justify-content-start align-items-center">
        <Col xs={12} md={{span: 7, order: 2}} className="mb-3">
          <img src={data.image} className="AboveTheFold-image" alt="test"/>
        </Col>
        <Col md={{order: 1}}>
          <Stack gap={3}>
            <div className="AboveTheFold-title">{data.title}</div>
            <div className="AboveTheFold-subtitle">{data.subtitle}</div>
            <Button
              className="App-button AboveTheFold-button"
              onClick={() => dispatch({type: SHOW})}
            >
              {data.call_to_action}
            </Button>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default AboveTheFold;
