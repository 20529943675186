import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import { SHOW } from '../state/signUpReducer.js';
import './Pricing.css';

const Pricing = ({data, dispatch}) => {
  const getColumns = () => {
    return data.tiers.map((tier, index) => {
      return (
        <Col key={index} xs={11} md={4} className="Pricing-column">
          <Row className={`Pricing-name-row ${tier.key}`}>
            <Col>{tier.display}</Col>
          </Row>
          <Row className="Pricing-price-row">
            <Col>{ getPrice(tier.key) }</Col>
          </Row>
          { getFeatures(tier.key) }
        </Col>
      );
    });
  };

  const getPrice = (tier) => {
    return data.pricing_price[tier];
  };

  const getFeatures = (tier) => {
    const rows = data.features[tier].map((feature, index) => {
      return (
        <Row data-aos="fade-up" className="mb-3 Pricing-feature-row" key={`feature-${index}`}>
          <Col>
            <div className="d-flex justify-content-center justify-content-md-start">
              <div className="me-2 Pricing-check"><BsFillPatchCheckFill/></div>
              <div>{ feature }</div>
            </div>
          </Col>
        </Row>
      );
    });
    return rows;
  }

  return (
    <>
      <div className="mt-4">
        <h2 className="Pricing-header" id="pricing">Pricing</h2>
      </div>
      <Container className="Pricing-container" data-aos="fade-up">
        <Row className="justify-content-center">
          { getColumns() }
        </Row>
      </Container>
      <div className="mb-5">
        <Button
          onClick={() => dispatch({type: SHOW}) }
          className="App-button"
        >
          Get Started
        </Button>
      </div>
    </>
  );
};

export default Pricing;
