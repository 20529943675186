import { useState } from 'react';
import { signUpEmail } from '../api/client';
import {
  Row,
  Col,
  Form,
  Button,
  Alert,
} from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { HIDE } from '../state/signUpReducer.js';
import ReCAPTCHA from 'react-google-recaptcha';
import './SignUpForm.css';

const SignUpForm = ({dispatch, data}) => {
  const [email, setEmail] = useState();
  const [complete, setComplete] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [error, setError] = useState('');

  const submitSignUp = () => {
    if (!email || email.trim().length === 0) {
      setError("Please provide a valid email address");
      return;
    }

    setRecaptcha(true);
  };

  const recaptchaChanged = (value) => {
    if (!value) {
      // return if no value
      return;
    }
    let response = signUpEmail(email, value);
    if (response.error) {
      setError(`Sign up failed: ${response.error}`);
      return;
    }

    setComplete(true);
  };

  const getRegularView = () => (
    <>
      <div className="d-flex align-items-center">
        <h2 className="me-auto">{data.sign_up_view_header}</h2>
        <GrClose
          style={{
            cursor: 'pointer'
          }}
          onClick={() => dispatch({type: HIDE})}
        />
      </div>
      <img src={data.sign_up_image} alt="sign up" className="SignUpForm-image"/>
      { recaptcha ? (
        <>
          <Row className="justify-content-center">
            <Col>
              {"One last step (to make sure you're actually human)"}
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
              <ReCAPTCHA
                sitekey={data.recaptcha_key}
                onChange={recaptchaChanged}
              />
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Form>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                onChange={(e) => {
                  setError('');
                  setEmail(e.target.value);
                }}
              /> 
            </Form.Group>
            <Form.Group className="mt-3">
              <Button
                onClick={() => submitSignUp()}
                variant="outline-primary"
                className="w-100"
              >
                Submit 🎉
              </Button>
            </Form.Group>
          </Form>
        </>
      )}
      { error && error.length > 0 && (
        <Alert className="mt-2" variant="danger">
          {error}
        </Alert>
      )}
    </>
  );

  const getCompletedView = () => (
    <>
      <div className="d-flex align-items-center">
        <h2 className="me-auto">{data.completed_header}</h2>
        <GrClose
          style={{
            cursor: 'pointer'
          }}
          onClick={() => dispatch({type: HIDE})}
        />
      </div>
      <img src={data.completed_image} alt="sign up complete" className="SignUpForm-image"/>
      <div className="mb-2">
        {data.completed_text}
      </div>
    </>
  );

  return complete ? getCompletedView() : getRegularView();
}

export default SignUpForm;
