const initialState = {
  showModal: false
};

const SHOW = "show";
const HIDE = "hide";

const reducer = (state, action) => {
  switch (action.type) {
    case SHOW:
      return {showModal: true}
    case HIDE:
      return {showModal: false}
    default:
      throw new Error();
  }
};

export {initialState, reducer, SHOW, HIDE}
