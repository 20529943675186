import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import './BenefitDetails.css';

const BenefitDetails = ({index, title, description, image}) => {
  const isEven = index % 2 === 0;
  return (
    <Container>
      <Row className="BenefitDetails-container align-items-center justify-content-around">
        <Col data-aos="fade-up" data-aos-duration={700} xs={{span: 12, order: 'last'}} md={{span: 5, order: (isEven ? 1 : 2)}}>
          <h4 className="BenefitDetails-title">{title}</h4>
          <p className="BenefitDetails-description">{description}</p>
        </Col>
        <Col data-aos="fade-up" data-aos-duration={500} xs={{span: 12, order: 'first'}} md={{span: 6, order: (isEven ? 2 : 1)}}>
          <img src={image} alt="test" className="mb-2 BenefitDetails-placeholder-image"/>
        </Col>
      </Row>
    </Container>
  );
};

export default BenefitDetails;
