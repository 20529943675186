import {
  Container,
} from 'react-bootstrap';
import BenefitDetails from './BenefitDetails';
import './Benefits.css';

const Benefits = ({data}) => {
  return (
    <Container fluid className="Benefits-container">
      {
        data.map((d, index) => {
          return (
            <BenefitDetails
              key={index}
              index={index}
              title={d.title}
              description={d.description}
              image={d.image}
            />
          )
        })
      }
    </Container>
  );
};

export default Benefits;
