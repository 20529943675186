import hero_img from './hero_img.png';
import the_logo from './the_logo.png';
import laptop_img from './laptop_img.png';
import search_img from './search_img.png';
import cdn_img from './cdn_img.png';
import easy_use_img from './easy_use_img.png';
import set_and_forget from './set_and_forget.png';

const AppName = "SoftwareLogos";
export const data = {
  sign_up : {
    sign_up_view_header: "Ready To Get Started?",
    sign_up_image: hero_img,
    recaptcha_key: '6LcCEKUfAAAAALQh11ZaZnSxy-kVt3N9rB2oeFd1',
    completed_header: "All Done!",
    completed_text: "Yay! Thanks for signing up! We're currently running a private Beta. We'll contact you at the email address you provided when we're live.",
    completed_image: hero_img
  },
  navigation: {
    logo_text: AppName,
    logo_image: the_logo,
  },
  above_the_fold: {
    title: "Third-Party Logos For Developers and Designers Made Easy",
    subtitle: "Effortlessly add logos of applications, services, and dev tools to your projects. Set it and forget it with auto-updating versions.\n\nNo more searching for pngs with \"transparent\" backgrounds.",
    image: laptop_img,
    call_to_action: "Get Access",
  },
  summary_blurb: "Surely you've got more important things to focus on than searching for and manually managing pngs. Let us take that off your plate.",
  social_proof: {
    startText: "Whether it's for a side-project, startup, or work project, we've got logos for you...",
    proofLogos: [
      'https://d3jdroprl91tfm.cloudfront.net/logo/87f6c86e-4c8c-475a-b710-3737a9751eb2/github_mark',
      'https://d3jdroprl91tfm.cloudfront.net/logo/084e2c8b-739c-4984-b520-87a84272831b/heroku',
      'https://d3jdroprl91tfm.cloudfront.net/logo/c20f0d43-cf70-4987-884d-02973d0adeb3/javascript',
      'https://d3jdroprl91tfm.cloudfront.net/logo/fbf4c1a9-6b9f-4716-9e85-69f804c3ea4a/apache_kafka_with_text',
      'https://d3jdroprl91tfm.cloudfront.net/logo/55f62e46-9871-47cd-aab9-9c0a8ab34a75/k8s',
      'https://d3jdroprl91tfm.cloudfront.net/logo/aa7424c7-1acb-4bee-a8f4-348b7fa5876b/aws_ec2',
      'https://d3jdroprl91tfm.cloudfront.net/logo/e2e47284-d798-46ed-aaee-984723f3cb0c/googlecloud_compute_engine',
      'https://d3jdroprl91tfm.cloudfront.net/logo/6fb5ad7d-c588-41c8-be3b-d1d009d778c7/postgresql',
      'https://d3jdroprl91tfm.cloudfront.net/logo/dba9d758-f1f0-42ba-bb8d-32ceacf77143/apache_airflow',
      'https://d3jdroprl91tfm.cloudfront.net/logo/b634795f-981e-438b-9cd5-ca64fa089b85/datadog',
      'https://d3jdroprl91tfm.cloudfront.net/logo/93aaef48-9493-4534-b07a-9b1d9d3a2c94/aws',
      'https://d3jdroprl91tfm.cloudfront.net/logo/6135d20c-c346-475b-9d8a-6126fe2a107d/docker_moby_color',
      'https://d3jdroprl91tfm.cloudfront.net/logo/8b11bff2-49ed-42ed-934a-b5189e4ebe1b/notion',
      'https://d3jdroprl91tfm.cloudfront.net/logo/4d2be7b1-7fa2-4aac-9a9d-2b7abd08fdc0/react',
      'https://d3jdroprl91tfm.cloudfront.net/logo/ce1127e6-d2a0-43a8-928c-dd4b538c579f/sentry',
      'https://d3jdroprl91tfm.cloudfront.net/logo/92cd3a6b-9544-4ffd-aef5-03b88bcff96d/aws_s3',
      'https://d3jdroprl91tfm.cloudfront.net/logo/d1cbb798-ba38-4799-9e80-e635f9119350/go_blue',
      'https://d3jdroprl91tfm.cloudfront.net/logo/340f4ac2-ead8-4584-8316-7bb60fe2e903/circleci_black_w_text_vertical',
    ],
    endText: "...and much more.",
  },
  benefits: [
    {
      title: 'Easy To Find',
      description: "We make it ridiculously easy to find the official logos you need with our search interface.\n\nJust enter the name of the service or tool and we surface the relevant logos and logo variations.",
      image: search_img
    },
    {
      title: 'Easy To Use',
      description: 'Using a logo is as easy as generating a unique link and using it anywhere you like (HTML img tags, CSS, or iphone/android apps).\n\nOr you can download the logo and host it yourself, if you\'re into that kind of thing.',
      image: easy_use_img
    },
    {
      title: 'Logos At Scale',
      description: 'Starting small or already have millions of requests/second? No problem. Our logos are lightning fast no matter your scale.\n\nOur logos are CDN backed and served from over 200 locations across the globe so you get speed + scale.',
      image: cdn_img
    },
    {
      title: 'Set It And Forget It',
      description: 'Set your logos to auto-update with new versions and never worry about them being stale again.\n\nOr pin your logos to specific versions if you want full control on how they are updated.\n\nAll without having to deploy new code.',
      image: set_and_forget
    },
  ],
  pricing: {
    tiers: [{
      key: 'hobby',
      display: 'Side-project'
    }, {
      key: 'explore',
      display: 'Startup'
    }, {
      key: 'serious',
      display: 'Business'
    }],
    pricing_price: {
      hobby: 'Free',
      explore: '$5/month',
      serious: '$15/month',
    },
    features: {
      hobby: [
        'Unlimited logo search',
        'Unlimited downloads',
        'Only 1 team member',
        'Up to 5 managed logo links',
        'Automatic updates (for managed logos)',
        'CDN backed logo links',
        'Up to 200k link requests per month',
      ],
      explore: [
        '... everything in Side-project, plus...',
        'Up to 10 team members',
        'Up to 30 managed logo links',
        'Up to 1 million link requests per month',
        'Pinnable logo versions',
        'Full control over logo versions',
      ],
      serious: [
        '... everything in Startup, plus...',
        'Unlimited team members',
        'Unlimited managed logo links',
        'Unlimited link requests per month'
      ]
    }
  },
  footer: {
    disclaimer: `We do not claim ownership of third-party logos displayed or managed by us. We do not promote misuse of trademarked property and ${AppName} is not liable for misuse of third-party content. Where applicable we strive to also include brand guidelines provided by logo owners. Remember to always consult the brand guidelines before using a third-party logo.`,
    text: "Made with ❤️ for devs by devs",
    incorporated: `© 2022 ${AppName}. All Rights Reserved`
  }
}
